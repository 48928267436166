<app-navbar></app-navbar>

<!-- Start Cyber Security Banner Area -->
<div class="cs-banner-section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="cs-banner-content">
                    <span class="sub-title">PROFESSIONAL COMPANY WITH RELIABLE SERVICE</span>
                    <h1>Cyber Security Expert</h1>
                    <p>Secure your Cyberspace, secure your Digital Life</p>

                    <ul class="banner-btn">
                        <li>
                            <a href="contact.html" class="default-btn">
                                Get Started <span></span>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube">
                                <i class="flaticon-play-button"></i> 
                                Play Video
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="cs-banner-image">
                    <img src="assets/img/cyber-security-home/banner/main.png" alt="image">
                </div>
            </div>
        </div>
    </div>
    <div class="cs-lock">
        <img src="assets/img/cyber-security-home/banner/lock.png" alt="image">
    </div>
</div>
<!-- End Cyber Security Banner Area -->

<div class="background-with-black-color">

    <!-- Start Card Area -->
    <div class="cs-card-area">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-3 col-sm-6">
                    <div class="cs-card-content">
                        <h3>
                            <a href="single-services.html">1. High Quality Services</a>
                        </h3>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="cs-card-content">
                        <h3>
                            <a href="single-services.html">2. Effective Protection</a>
                        </h3>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="cs-card-content">
                        <h3>
                            <a href="single-services.html">3. Provide Advanced Security</a>
                        </h3>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="cs-card-content">
                        <h3>
                            <a href="single-services.html">4. Complete Website Security</a>
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Card Area -->

    <!-- Start Features Area -->
    <div class="cs-features-area">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-sm-6 p-0">
                    <div class="cs-features-item" style="background-image: url(assets/img/cyber-security-home/features/features-1.jpg)">
                        <div class="content">
                            <h3>
                                <a href="single-services.html">Application Security</a>
                            </h3>
                            <p>Lorem ipsum dolor sit amLorem ipsum dolor sit amet is consectetur adipiscing elit sed do.</p>
                            <a href="single-services.html" class="get-started-btn">GET STARTED</a>
                        </div>
                        <div class="bg-icon">
                            <i class="fa-solid fa-lock"></i>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 p-0">
                    <div class="cs-features-item" style="background-image: url(assets/img/cyber-security-home/features/features-2.jpg)">
                        <div class="content">
                            <h3>
                                <a href="single-services.html">Cloud Security</a>
                            </h3>
                            <p>Lorem ipsum dolor sit amLorem ipsum dolor sit amet is consectetur adipiscing elit sed do.</p>
                            <a href="single-services.html" class="get-started-btn">GET STARTED</a>
                        </div>
                        <div class="bg-icon">
                            <i class="fa-solid fa-cloud"></i>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 p-0">
                    <div class="cs-features-item" style="background-image: url(assets/img/cyber-security-home/features/features-3.jpg)">
                        <div class="content">
                            <h3>
                                <a href="single-services.html">Infrastructure Security</a>
                            </h3>
                            <p>Lorem ipsum dolor sit amLorem ipsum dolor sit amet is consectetur adipiscing elit sed do.</p>
                            <a href="single-services.html" class="get-started-btn">GET STARTED</a>
                        </div>
                        <div class="bg-icon">
                            <i class="fa-solid fa-shield"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Features Area -->

    <!-- Start CS Partner Area -->
    <div class="cs-partner-area pt-100 pb-70">
        <div class="container-fluid">
            <div class="section-title with-line-text">
                <span class="sub-title">THE POWER OF KARLSON</span>
                <h2>Karlson Has Been Trusted <span>Over 2300 +</span> Companies In The World</h2>
            </div>

            <div class="cs-partner-slides owl-carousel owl-theme">
                <div class="cs-partner-item">
                    <a href="#">
                        <img src="assets/img/cyber-security-home/partner/partner-1.png" alt="image">
                    </a>
                </div>
                <div class="cs-partner-item">
                    <a href="#">
                        <img src="assets/img/cyber-security-home/partner/partner-2.png" alt="image">
                    </a>
                </div>
                <div class="cs-partner-item">
                    <a href="#">
                        <img src="assets/img/cyber-security-home/partner/partner-3.png" alt="image">
                    </a>
                </div>
                <div class="cs-partner-item">
                    <a href="#">
                        <img src="assets/img/cyber-security-home/partner/partner-4.png" alt="image">
                    </a>
                </div>
                <div class="cs-partner-item">
                    <a href="#">
                        <img src="assets/img/cyber-security-home/partner/partner-5.png" alt="image">
                    </a>
                </div>
                <div class="cs-partner-item">
                    <a href="#">
                        <img src="assets/img/cyber-security-home/partner/partner-6.png" alt="image">
                    </a>
                </div>
                <div class="cs-partner-item">
                    <a href="#">
                        <img src="assets/img/cyber-security-home/partner/partner-7.png" alt="image">
                    </a>
                </div>
                <div class="cs-partner-item">
                    <a href="#">
                        <img src="assets/img/cyber-security-home/partner/partner-8.png" alt="image">
                    </a>
                </div>
            </div>
        </div>
    </div>
    <!-- End CS Partner Area -->

    <!-- Start CS About Area -->
    <div class="cs-about-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="cs-about-image">
                        <img src="assets/img/cyber-security-home/about.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="cs-about-content">
                        <span class="sub-title">ABOUT KARLSON</span>
                        <h3>Secure Your Clicks, <span>Secure Your</span> Business Reputation</h3>
                        <p>We work hand-in-hand with industry-leading brands to help redefine the possibilities and potential of digital engagements We work hand-in-hand with industry-leading brands to help redefine the possibilities and potential.</p>

                        <div class="row justify-content-center">
                            <div class="col-lg-6 col-sm-6">
                                <div class="cs-about-card">
                                    <div class="icon">
                                        <i class="fa-solid fa-lock"></i>
                                    </div>
                                    <h4>Endpoint Security</h4>
                                    <p>Lorem ipsum dolor sit amet, is demo consectetur adipiscing elit.</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6">
                                <div class="cs-about-card">
                                    <div class="icon">
                                        <i class="fa-solid fa-shield"></i>
                                    </div>
                                    <h4>High Quality Services</h4>
                                    <p>Lorem ipsum dolor sit amet, is demo consectetur adipiscing elit.</p>
                                </div>
                            </div>
                        </div>
                        <div class="about-btn">
                            <a href="contact.html" class="default-btn">
                                Get Started <span></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End CS About Area -->

    <!-- Start CS Choose Area -->
    <div class="cs-choose-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="cs-choose-content">
                        <span class="sub-title">WHY CHOOSE KARLSON</span>
                        <h3>Reduce Attacker <span>Time With</span> Early Detection & Derailment Of In-network Threats</h3>
                        <p>We work hand-in-hand with industry-leading brands to help redefine the possibilities and potential of digital engagements We work hand-in-hand with industry-leading brands to help redefine the possibilities and potential.</p>

                        <ul class="list">
                            <li><i class="fa-solid fa-check"></i> Premium Services And Beyond Your Expectation</li>
                            <li><i class="fa-solid fa-check"></i> World Class Cyber Security Tech</li>
                            <li><i class="fa-solid fa-check"></i> Quick Alert With Auto Action & Low Maintenance</li>
                        </ul>
                        <div class="choose-btn">
                            <a href="contact.html" class="default-btn">
                                Get Started <span></span>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="cs-choose-image">
                        <img src="assets/img/cyber-security-home/choose.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End CS Choose Area -->

    <!-- Start CS Services Area -->
    <div class="cs-services-area pb-70">
        <div class="container">
            <div class="section-title with-line-text">
                <span class="sub-title">OUR SERVICES</span>
                <h2>The Power To Protect Your <span>Cyberspace</span> & Complete Website Security</h2>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-3 col-sm-6">
                    <div class="cs-services-card text-center">
                        <div class="image">
                            <img src="assets/img/cyber-security-home/services/services-1.png" alt="image">
                        </div>
                        <h3>
                            <a href="single-services.html">Easy To Transact</a>
                        </h3>
                        <p>Lorem ipsum dolor sit amet, is consectetur adipiscing elit, sed do eiusmo.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="cs-services-card text-center">
                        <div class="image">
                            <img src="assets/img/cyber-security-home/services/services-2.png" alt="image">
                        </div>
                        <h3>
                            <a href="single-services.html">Secure Managed IT</a>
                        </h3>
                        <p>Lorem ipsum dolor sit amet, is consectetur adipiscing elit, sed do eiusmo.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="cs-services-card text-center">
                        <div class="image">
                            <img src="assets/img/cyber-security-home/services/services-3.png" alt="image">
                        </div>
                        <h3>
                            <a href="single-services.html">Data Protection</a>
                        </h3>
                        <p>Lorem ipsum dolor sit amet, is consectetur adipiscing elit, sed do eiusmo.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="cs-services-card text-center">
                        <div class="image">
                            <img src="assets/img/cyber-security-home/services/services-4.png" alt="image">
                        </div>
                        <h3>
                            <a href="single-services.html">Incident Responder</a>
                        </h3>
                        <p>Lorem ipsum dolor sit amet, is consectetur adipiscing elit, sed do eiusmo.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="cs-services-card text-center">
                        <div class="image">
                            <img src="assets/img/cyber-security-home/services/services-5.png" alt="image">
                        </div>
                        <h3>
                            <a href="single-services.html">Data Encryption</a>
                        </h3>
                        <p>Lorem ipsum dolor sit amet, is consectetur adipiscing elit, sed do eiusmo.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="cs-services-card text-center">
                        <div class="image">
                            <img src="assets/img/cyber-security-home/services/services-6.png" alt="image">
                        </div>
                        <h3>
                            <a href="single-services.html">Disaster Planning</a>
                        </h3>
                        <p>Lorem ipsum dolor sit amet, is consectetur adipiscing elit, sed do eiusmo.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="cs-services-card text-center">
                        <div class="image">
                            <img src="assets/img/cyber-security-home/services/services-7.png" alt="image">
                        </div>
                        <h3>
                            <a href="single-services.html">Threat Hunter</a>
                        </h3>
                        <p>Lorem ipsum dolor sit amet, is consectetur adipiscing elit, sed do eiusmo.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="cs-services-card text-center">
                        <div class="image">
                            <img src="assets/img/cyber-security-home/services/services-8.png" alt="image">
                        </div>
                        <h3>
                            <a href="single-services.html">Data Recovery</a>
                        </h3>
                        <p>Lorem ipsum dolor sit amet, is consectetur adipiscing elit, sed do eiusmo.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End CS Services Area -->

    <!-- Start CS Fun Facts Area -->
    <section class="cs-fun-facts-area pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="cs-single-fun-facts">
                        <div class="number">01.</div>
                        <h3><span class="odometer" data-count="2250">00</span><span class="sign-icon">+</span></h3>
                        <p>Cybersecurity Projects</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="cs-single-fun-facts">
                        <div class="number">02.</div>
                        <h3><span class="odometer" data-count="15000">00</span><span class="sign-icon">+</span></h3>
                        <p>All Time Clients</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="cs-single-fun-facts">
                        <div class="number">03.</div>
                        <h3><span class="odometer" data-count="160">00</span><span class="sign-icon">+</span></h3>
                        <p>Attacks Blocked</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="cs-single-fun-facts">
                        <div class="number">04.</div>
                        <h3><span class="odometer" data-count="80">00</span><span class="sign-icon">+</span></h3>
                        <p>Experts Team</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End CS Fun Facts Area -->

    <!-- Start Leading Technology Area -->
    <div class="leading-technology-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="leading-technology-image">
                        <img src="assets/img/cyber-security-home/leading-technology.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="leading-technology-content">
                        <span class="sub-title">LEADING TECHNOLOGY FIRM</span>
                        <h3>Provide Advanced <span>Security</span> For Threat And Effective Protection</h3>
                        <p>We work hand-in-hand with industry-leading brands to help redefine the possibilities and potential of digital engagements We work hand-in-hand with industry-leading brands to help redefine the possibilities and potential.</p>

                        <div class="row justify-content-center">
                            <div class="col-lg-6 col-sm-6">
                                <ul class="list">
                                    <li>Network Detection</li>
                                    <li>Managing Cloud Security</li>
                                    <li>Website Hack Repair</li>
                                </ul>
                            </div>
                            <div class="col-lg-6 col-sm-6">
                                <ul class="list">
                                    <li>Network Trafic Analysis</li>
                                    <li>Hybrid Cloud Security</li>
                                    <li>24/7 Security Support</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Leading Technology Area -->

    <!-- Start CS Overview Area -->
    <div class="cs-overview-area pb-70">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="cs-overview-item">
                        <div class="row justify-content-center align-items-center">
                            <div class="col-lg-4 col-md-6">
                                <div class="cs-overview-image">
                                    <img src="assets/img/cyber-security-home/overview-1.png" alt="image">
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-6">
                                <div class="cs-overview-content">
                                    <h3>Personal Online Protection Everywhere We Go</h3>
                                    <a href="single-services.html" class="get-started-btn">GET STARTED</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="cs-overview-item">
                        <div class="row justify-content-center align-items-center">
                            <div class="col-lg-4 col-md-6">
                                <div class="cs-overview-image">
                                    <img src="assets/img/cyber-security-home/overview-2.png" alt="image">
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-6">
                                <div class="cs-overview-content">
                                    <h3>Simple Solution For Your Business's Security</h3>
                                    <a href="single-services.html" class="get-started-btn">GET STARTED</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End CS Overview Area -->

    <!-- Start Unique Feedback Area -->
    <div class="unique-feedback-area wrap-with-black-color pb-100">
        <div class="container">
            <div class="section-title with-line-text">
                <span class="sub-title">THOUGHTS OF OUR CLIENT</span>
                <h2>These People Have <span>Already</span> Experienced Our Review</h2>
            </div>

            <div class="unique-feedback-slides owl-carousel owl-theme">
                <div class="unique-single-feedback with-black-color">
                    <ul class="rating">
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                    </ul>
                    <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>
                    <div class="client-info">
                        <img src="assets/img/cryptocurrency-home/user1.jpg" alt="image">

                        <h3>Jason Roy</h3>
                        <span>Manager</span>
                    </div>
                    <div class="quote">
                        <i class="fa-solid fa-quote-right"></i>
                    </div>
                </div>

                <div class="unique-single-feedback with-black-color">
                    <ul class="rating">
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                    </ul>
                    <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>
                    <div class="client-info">
                        <img src="assets/img/cryptocurrency-home/user2.jpg" alt="image">

                        <h3>James Anderson</h3>
                        <span>Web Developer</span>
                    </div>
                    <div class="quote">
                        <i class="fa-solid fa-quote-right"></i>
                    </div>
                </div>

                <div class="unique-single-feedback with-black-color">
                    <ul class="rating">
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                    </ul>
                    <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>
                    <div class="client-info">
                        <img src="assets/img/cryptocurrency-home/user3.jpg" alt="image">

                        <h3>Jason Roy</h3>
                        <span>Manager</span>
                    </div>
                    <div class="quote">
                        <i class="fa-solid fa-quote-right"></i>
                    </div>
                </div>

                <div class="unique-single-feedback with-black-color">
                    <ul class="rating">
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                    </ul>
                    <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>
                    <div class="client-info">
                        <img src="assets/img/cryptocurrency-home/user1.jpg" alt="image">

                        <h3>James Anderson</h3>
                        <span>Web Developer</span>
                    </div>
                    <div class="quote">
                        <i class="fa-solid fa-quote-right"></i>
                    </div>
                </div>

                <div class="unique-single-feedback with-black-color">
                    <ul class="rating">
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                    </ul>
                    <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>
                    <div class="client-info">
                        <img src="assets/img/cryptocurrency-home/user2.jpg" alt="image">

                        <h3>Jason Roy</h3>
                        <span>Manager</span>
                    </div>
                    <div class="quote">
                        <i class="fa-solid fa-quote-right"></i>
                    </div>
                </div>

                <div class="unique-single-feedback with-black-color">
                    <ul class="rating">
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                    </ul>
                    <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>
                    <div class="client-info">
                        <img src="assets/img/cryptocurrency-home/user3.jpg" alt="image">

                        <h3>James Anderson</h3>
                        <span>Web Developer</span>
                    </div>
                    <div class="quote">
                        <i class="fa-solid fa-quote-right"></i>
                    </div>
                </div>
            </div>
        </div>

        <div class="unique-feedback-line-bg">
            <img src="assets/img/cyber-security-home/line-bg.png" alt="image">
        </div>
    </div>
    <!-- End Unique Feedback Area -->

    <!-- Start Blog Area -->
    <div class="blog-wrap-area pb-70">
        <div class="container">
            <div class="section-title with-line-text">
                <span class="sub-title">LATEST NEWS</span>
                <h2>Explore, Learn And Stay <span>Up To Date</span> With The Latest In Cyber</h2>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-card with-black-color">
                        <div class="tag">
                            <span>LATEST NEWS</span>
                        </div>
                        <ul class="post-meta">
                            <li><i class='bx bxs-calendar'></i> 17th January</li>
                            <li><i class='bx bx-message-dots'></i> 0 comment</li>
                        </ul>
                        <h3>
                            <a href="single-blog.html">The Launch Of A Revamped User Referral Program</a>
                        </h3>
                        <p>We work hand-in-hand with industry-leading brands to help redefine the possibilities and potential of digital engagements We work.</p>
                        <div class="info">
                            <img src="assets/img/cryptocurrency-home/user1.jpg" alt="image">
    
                            <h3>Thomas Adison</h3>
                            <span>Manager</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-card with-black-color">
                        <div class="tag">
                            <span>LATEST NEWS</span>
                        </div>
                        <ul class="post-meta">
                            <li><i class='bx bxs-calendar'></i> 17th January</li>
                            <li><i class='bx bx-message-dots'></i> 0 comment</li>
                        </ul>
                        <h3>
                            <a href="single-blog.html">Complete Protection For All Your Devices</a>
                        </h3>
                        <p>We work hand-in-hand with industry-leading brands to help redefine the possibilities and potential of digital engagements We work.</p>
                        <div class="info">
                            <img src="assets/img/cryptocurrency-home/user2.jpg" alt="image">
    
                            <h3>James Anderson</h3>
                            <span>Web Developer</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-card with-black-color">
                        <div class="tag">
                            <span>LATEST NEWS</span>
                        </div>
                        <ul class="post-meta">
                            <li><i class='bx bxs-calendar'></i> 17th January</li>
                            <li><i class='bx bx-message-dots'></i> 0 comment</li>
                        </ul>
                        <h3>
                            <a href="single-blog.html">Online Security For Your Devices Antivirus</a>
                        </h3>
                        <p>We work hand-in-hand with industry-leading brands to help redefine the possibilities and potential of digital engagements We work.</p>
                        <div class="info">
                            <img src="assets/img/cryptocurrency-home/user3.jpg" alt="image">
    
                            <h3>Sarah Taylor</h3>
                            <span>Designer</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Blog Area -->

</div>

<!-- Start Subscribe Wrap Area -->
<div class="subscribe-wrap-area with-black-color">
    <div class="container">
        <div class="subscribe-wrap-inner-box">
            <div class="subscribe-content">
                <h2>Join Our Newsletter</h2>

                <form class="newsletter-form" data-toggle="validator">
                    <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL" required autocomplete="off">

                    <button type="submit">Subscribe</button>
                    <div id="validator-newsletter" class="form-result"></div>
                </form>
            </div>

            <div class="subscribe-shape-1">
                <img src="assets/img/cryptocurrency-home/subscribe/shape-1.png" alt="image">
            </div>
            <div class="subscribe-shape-2">
                <img src="assets/img/cryptocurrency-home/subscribe/shape-2.png" alt="image">
            </div>
            <div class="subscribe-shape-3">
                <img src="assets/img/cryptocurrency-home/subscribe/shape-3.png" alt="image">
            </div>
        </div>
    </div>
</div>
<!-- End Subscribe Wrap Area -->