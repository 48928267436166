<!-- Start Footer Area -->
<section class="footer-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>CƠ QUAN CHỦ QUẢN:<br/> SỞ THÔNG TIN VÀ TRUYỀN THÔNG PHÚ THỌ</h3>
                    <ul class="footer-contact-info">
                        <li>
                            <i class="flaticon-phone-call"></i>
                            <span>Điện thoại</span>
                            <a href="tel:0210.3811486">0210.3811486</a>
                            <span>Fax</span>
                            <a href="tel:0210.3811485">0210.3811485</a>
                        </li>
                        <li>
                            <i class="flaticon-email"></i>
                            <span>Email</span>
                            <a href="mailto:sotttt@phutho.gov.vn">sotttt@phutho.gov.vn</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Vị trí của tỉnh</h3>
                    <ul class="footer-quick-links">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d421213.97719953756!2d105.00270285554477!3d21.27296018388776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31348379dd903cfb%3A0x30ec8de0c8c8646e!2zUGjDuiBUaOG7jSwgVmnhu4d0IE5hbQ!5e1!3m2!1svi!2s!4v1665128360423!5m2!1svi!2s"
                            width="400" height="250" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </ul>
                </div>
            </div>
        </div>
        <div class="copyright-area">
        </div>
    </div>
</section>
<!-- End Footer Area -->