import { NbToastrService } from '@nebular/theme';
import { Injectable } from '@angular/core';
import { SUCCESS_NOTICE, TITLE_NOTICE } from './contants';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private toastrService: NbToastrService,
  ) { }

  toastrSuccess(message?: string, title?: string) {
    this.toastrService.success(message ?? SUCCESS_NOTICE, title ?? TITLE_NOTICE, { icon: '' });
  }

  toastrPrimary(message: string, title?: string) {
    this.toastrService.primary(message, title ?? TITLE_NOTICE, { icon: '' });
  }

  toastrWarning(message: string, title?: string) {
    this.toastrService.warning(message, title ?? TITLE_NOTICE, { icon: '' });
  }

  toastrDanger(message: string, title?: string) {
    this.toastrService.danger(message, title ?? TITLE_NOTICE, { icon: '' });
  }

  toastrInfo(message: string, title?: string) {
    this.toastrService.info(message, title ?? TITLE_NOTICE, { icon: '' });
  }

  toastrShow(message: string, title?: string) {
    this.toastrService.show(message, title ?? TITLE_NOTICE, { icon: '' });
  }

  toastrDefault(message: string, title?: string) {
    this.toastrService.default(message, title ?? TITLE_NOTICE, { icon: '' });
  }
}
