import { AuthService } from './common/auth.service';
import { Component, OnInit, OnDestroy } from "@angular/core";
import {
    Router,
    NavigationStart,
    NavigationCancel,
    NavigationEnd,
    ActivatedRoute
} from "@angular/router";
import {
    Location,
    LocationStrategy,
    PathLocationStrategy
} from "@angular/common";
import { filter } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { HttpParams } from "@angular/common/http";
import { LST_APP, SUCCESS_RESPONSE } from "./common/contants";
import { AppQthtApiService } from "./common/app-qtht.service";
declare let $: any;

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    providers: [
        Location,
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit, OnDestroy {
    location: any;
    routerSubscription: any;
    path: string = environment.APP_HOME_PATH;
    navigationSubscription: any;
    constructor(
        private router: Router,
        private _activatedRoute:ActivatedRoute,
        private QthtApiService: AppQthtApiService,
    ) {}

    async ngOnInit() {
        this.recallJsFuntions();
        let appIdLink = null;

        if(localStorage.getItem('LinkApp') == null &&  localStorage.getItem('appLogOutRedirect') == null){   //đăng nhập từ trang chủ sso
            await this.getAppidSSO();
        }

        const routerData = this.getParamValueQueryString('code');
        const appLinkLogout = localStorage.getItem('appLogOutRedirect');

        if (routerData != null) {
            localStorage.setItem("authCode", routerData);
            if(appIdLink == null) {
                appIdLink = localStorage.getItem('LinkApp');// Đăng nhập từ các lĩnh vực
            }
        }

        if(appLinkLogout != null && appIdLink == null) {
            appIdLink = appLinkLogout;
        }


        if(appIdLink != null) {
            this.navigationSubscription = this.router.events.subscribe((e: any) => {
                // If it is a NavigationEnd event re-initalise the component
                if (e instanceof NavigationEnd) {
                    this.initialiseInvites(Number(appIdLink));
                    location.reload();
                    localStorage.removeItem("appLogOutRedirect");
                }
            });
        }
    }

    async getAppidSSO(){
        const routerData = this.getParamValueQueryString('code');
        const res = await this.QthtApiService.appQthtApiSsoGetappid(routerData).toPromise()
        if(res.code === SUCCESS_RESPONSE){
            this.getLinkLinhVuc(Number.parseInt(res.data));
            localStorage.setItem('LinkApp', res.data);
        }
        else{
            console.log(res.message);
        }
    }

    getLinkLinhVuc(appId: number){
        if(appId === 201) {
           return window.location.href = environment.APP_PROVIDER + '/oauth2/authorize' + encodeURI('?') +'response_type=code' + encodeURI('&') + 'client_id=' + environment.APP_GDDT_CLIENT_ID + encodeURI('&') +'redirect_uri=' + environment.APP_GDDT_REDIRECT + encodeURI('&') +'scope=openid';
        } else if(appId === 84) {
            return window.location.href =environment.APP_PROVIDER + '/oauth2/authorize' + encodeURI('?') +'response_type=code'+encodeURI('&') + 'client_id=' + environment.APP_GTVT_CLIENT_ID + encodeURI('&') +'redirect_uri=' + environment.APP_GDDT_REDIRECT + encodeURI('&') +'scope=openid';
        } else if(appId === 181) {
            return window.location.href =environment.APP_PROVIDER + '/oauth2/authorize' + encodeURI('?') +'response_type=code'+encodeURI('&') + 'client_id=' + environment.APP_KEHOACHDAUTU_CLIENT_ID + encodeURI('&') +'redirect_uri=' + environment.APP_GDDT_REDIRECT + encodeURI('&') +'scope=openid';
        } else if(appId === 424) {
            return window.location.href = environment.APP_PROVIDER + '/oauth2/authorize' + encodeURI('?') +'response_type=code'+encodeURI('&') + 'client_id=' + environment.APP_LDTBXH_CLIENT_ID + encodeURI('&') +'redirect_uri=' + environment.APP_GDDT_REDIRECT + encodeURI('&') +'scope=openid';
        } else if(appId === 284) {
            return window.location.href = environment.APP_PROVIDER + '/oauth2/authorize' + encodeURI('?') +'response_type=code'+encodeURI('&') + 'client_id=' + environment.APP_TNMT_CLIENT_ID + encodeURI('&') +'redirect_uri=' + environment.APP_GDDT_REDIRECT + encodeURI('&') +'scope=openid';
        } else if(appId === 41) {
            return window.location.href = environment.APP_PROVIDER + '/oauth2/authorize' + encodeURI('?') +'response_type=code'+encodeURI('&') + 'client_id=' + environment.APP_TAICHINH_CLIENT_ID + encodeURI('&') +'redirect_uri=' + environment.APP_GDDT_REDIRECT + encodeURI('&') +'scope=openid';
        } else if(appId === 61) {
            return window.location.href = environment.APP_PROVIDER + '/oauth2/authorize' + encodeURI('?') +'response_type=code'+encodeURI('&') + 'client_id=' + environment.APP_SONOIVU_CLIENT_ID + encodeURI('&') +'redirect_uri=' + environment.APP_GDDT_REDIRECT + encodeURI('&') +'scope=openid';
        } else if(appId === 384) {
            return window.location.href = environment.APP_PROVIDER + '/oauth2/authorize' + encodeURI('?') +'response_type=code'+encodeURI('&') + 'client_id=' + environment.APP_KCNDL_CLIENT_ID + encodeURI('&') +'redirect_uri=' + environment.APP_GDDT_REDIRECT + encodeURI('&') +'scope=openid';
        } else if(appId === 404) {
            return window.location.href = environment.APP_GIS_SSO + '/oauth2/authorize' + encodeURI('?') +'response_type=code'+encodeURI('&') + 'client_id=' + environment.APP_GIS_CLIENT_ID + encodeURI('&') +'redirect_uri=' + environment.APP_GIS_REDIRECT + encodeURI('&') +'scope=openid';
        } else if(appId === 221) {
            return window.location.href = environment.APP_PROVIDER + '/oauth2/authorize' + encodeURI('?') +'response_type=code'+encodeURI('&') + 'client_id=' + environment.APP_SOYTE_CLIENT_ID + encodeURI('&') +'redirect_uri=' + environment.APP_GDDT_REDIRECT + encodeURI('&') +'scope=openid';
        }
    }

    initialiseInvites(appId: number) {
        if(appId === LST_APP.APP_GDDT) {
            this.router.navigateByUrl('/gddt/SitePages/default.aspx'+ encodeURI('#') +'/app-gddt/home');
        } else if(appId === LST_APP.APP_GTVT) {
            this.router.navigateByUrl('/gtvt/SitePages/default.aspx'+ encodeURI('#') +'/gtvt/home');
        } else if(appId === LST_APP.APP_KHDT) {
            this.router.navigateByUrl('/khdt/SitePages/default.aspx'+ encodeURI('#') +'/app-khdt/home');
        } else if(appId === LST_APP.APP_LDVL) {
            this.router.navigateByUrl('/ldvl/SitePages/default.aspx'+ encodeURI('#') +'/ldtbxh/home');
        } else if(appId === LST_APP.APP_TNMT) {
            this.router.navigateByUrl('/tnmt/SitePages/default.aspx'+ encodeURI('#') +'/tainguyen/home');
        } else if(appId === LST_APP.APP_TAICHINH) {
            this.router.navigateByUrl('/taichinh/SitePages/default.aspx'+ encodeURI('#') +'/tai-chinh/bc-congkhai-ngansach');
        } else if(appId === LST_APP.APP_NOIVU) {
            this.router.navigateByUrl('/noivu/SitePages/default.aspx'+ encodeURI('#') +'/app-snv/home');
        } else if(appId === LST_APP.APP_KCNDL) {
            this.router.navigateByUrl('/kcndl/SitePages/default.aspx'+ encodeURI('#') +'/app-kcndl/home');
        } else if(appId === LST_APP.APP_YTE) {
            this.router.navigateByUrl('/yte/SitePages/default.aspx'+ encodeURI('#') +'/app-yte/home');
        } else if(appId === LST_APP.APP_GIS) {
            this.router.navigateByUrl('/gis/SitePages/default.aspx'+ encodeURI('#') +'/app-home');
        }
    }

    getParamValueQueryString( paramName ) {
        const url = window.location.href;
        let paramValue;
        if (url.includes('?')) {
          const httpParams = new HttpParams({ fromString: url.split('?')[1] });
          paramValue = httpParams.get(paramName);
        }
        return paramValue;
      }

    recallJsFuntions() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                $(".preloader").fadeIn("slow");
            }
        });
        this.routerSubscription = this.router.events
            .pipe(
                filter(
                    event =>
                        event instanceof NavigationEnd ||
                        event instanceof NavigationCancel
                )
            )
            .subscribe(event => {
                $.getScript(this.path + "/assets/js/custom.js");
                $(".preloader").fadeOut("slow");
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }

    ngOnDestroy() {
        this.routerSubscription.unsubscribe();
        if (this.navigationSubscription) {
            this.navigationSubscription.unsubscribe();
        }
    }
}
