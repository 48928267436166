<!-- Sidebar Modal -->
<div class="sidebar-modal">
    <div class="sidebar-modal-inner">
        <div class="sidebar-about-area">
            <div class="title">
                <h2>Giới thiệu</h2>
                <p><b class="color">Kho dữ liệu và cổng chuyển đổi số tỉnh Phú Thọ</b> là nền tảng quan trọng trong việc triển khai xây dựng đô thị thông minh; phục vụ triển khai các hệ thống thông tin, ứng dụng phục vụ người dân, doanh nghiệp và công tác
                    quản lý, chỉ đạo điều hành của tỉnh, đồng thời đây là nguồn cung cấp dữ liệu làm cơ sở phát triển Hệ sinh thái dữ liệu mở cho Tỉnh. Trên cơ sở Kho dữ liệu dùng chung, <b class="color">Kho dữ liệu và cổng chuyển đổi số tỉnh Phú Thọ</b>                    được phát triển để tạo ra phương thức chia sẻ tài nguyên dữ liệu từ Kho dữ liệu dùng chung với người dân, doanh nghiệp và tổ chức. Thông qua <b class="color">Kho dữ liệu và cổng chuyển đổi số tỉnh Phú Thọ</b>, Tỉnh mong muốn người
                    dân, doanh nghiệp và tổ chức <br /> + Tham gia giám sát, quản lý các mặt hoạt động của chính quyền, xã hội; <br /> + Sử dụng dữ liệu mở để tạo ra sản phẩm giá trị mới đóng góp cho hệ sinh thái ứng dụng, tiện ích của Tỉnh để phục vụ
                    người dân tốt hơn; <br /> + Góp phần thúc đẩy phát triển hệ sinh thái khởi nghiệp sáng tạo, phát triển nền kinh tế số của Tỉnh. <br /> + Đóng góp dữ liệu được hình thành trong quá trình khai thác để phát triển Hệ sinh thái dữ liệu
                    mở của Tỉnh. </p>
            </div>
        </div>
        <span class="close-btn sidebar-modal-close-btn"><i class="flaticon-close"></i></span>
    </div>
</div>
<!-- End Sidebar Modal -->