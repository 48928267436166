// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
// APP_QTHT_URL: 'http://localhost:25020',
// APP_HOME_PATH: '',
  APP_QTHT_URL: 'https://demo-apidata.phutho.gov.vn',
  APP_HOME_PATH: '/_layouts/15/phutho/appHome',
  APP_PROVIDER: 'https://ids.phutho.gov.vn',
  APP_GDDT_REDIRECT: 'https://demo-data.phutho.gov.vn/SitePages/homeOP.aspx/',
  APP_GDDT_CLIENT_ID: '_BxU0CqsoLtqkYiqHeOms7PT5Pwa',
  APP_GDDT_LINK: 201,
  APP_GTVT_CLIENT_ID: 'FaOmTy28DjZHCmcy30XqnpR0Rb4a',
  APP_GTVT_LINK: 84,
  APP_KCNDL_CLIENT_ID: '1ckgufq6YJQrd99Cs0AgMurskZAa',
  APP_KCNDL_LINK: 384,
  APP_KEHOACHDAUTU_CLIENT_ID: 'IxUkXjqHMdXIY3b69ZwylQd9HH8a',
  APP_KEHOACHDAUTU_LINK: 181,
  APP_LDTBXH_CLIENT_ID: 'e3oObBERggpdBNeUwr0_7k25NrUa',
  APP_LDTBXH_LINK: 424,
  APP_SONOIVU_CLIENT_ID: 'bzAjziN0SRsuA_UvRhJdzny5rf8a',
  APP_SONOIVU_LINK: 61,
  APP_TAICHINH_CLIENT_ID: 'fd1LAcBXdiv7o6vpxGn2UMXhoe8a',
  APP_TAICHINH_LINK: 41,
  APP_TNMT_CLIENT_ID: 'aqLWsCtGv0udmsJRPJqs_9PnpF8a',
  APP_TNMT_LINK: 284,
  APP_SOYTE_CLIENT_ID: 'VM309aMS_fS1L01L7kCl95tahqEa',
  APP_SOYTE_LINK: 221,
  APP_GIS_SSO: 'https://ids.phutho.gov.vn',
  APP_GIS_REDIRECT: 'https://demo-gis.phutho.gov.vn/',
  APP_GIS_CLIENT_ID: 'gLgcXnilqgxR2CIUdzJp3CIL8bwa',
  APP_GIS_LINK: 404,
};

/*

  APP_QTHT_URL: 'http://localhost:2500',
  APP_GDDT_URL: 'http://localhost:2500',
  APP_HOME_PATH: '/_layouts/15/App_home'

 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
