import { InsertUpdateModel, SysuserEntity } from './../../../common/app-qtht.service';
import { CommonService } from './../../../common/common.service';
import { listSex, SUCCESS_RESPONSE } from './../../../common/contants';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HomeDemoSixService } from '../home-demo-six/home-demo-six.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  constructor(
    private service: HomeDemoSixService,
    private commonService: CommonService,
    private router: Router
  ) { }
  path: string = environment.APP_HOME_PATH;
  listSex = listSex;
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
  rePassword: string = '';
  ngOnInit(): void {
  }
  target = new SysuserEntity();
  targetx: any = {};
  data = new InsertUpdateModel({
      userData: this.target,
      listGroupId : [282]
  });

  checkRepassword() {
    if (this.target.password === '' || this.rePassword === '') {
      return false;
    }
    if (this.target.password === this.rePassword)
      return false;
    else
      return true;
  }

  save(number) {
    this.data.userData.loaiuser = number;
    this.service.Insertuser(this.data).subscribe((result) => {
        if (result.code === SUCCESS_RESPONSE) {
          this.commonService.toastrSuccess();
          this.router.navigate(['/login']).then(() => {
            this.commonService.toastrSuccess('Đăng nhập thành công !');
          });
        } else {
          this.commonService.toastrDanger(result.message);
        }
      });
  }

  inputType = 'password';
  showHideClass = 'fa fa-eye';
  inputTypeMK = 'password';
  showHideClassMK = 'fa fa-eye';

  showPassword() {
    if (this.target.password !== null) {
      if(this.inputType === 'password') {
        this.inputType = 'text';
        this.showHideClass = 'fa fa-eye-slash';
      } else {
        this.inputType = 'password';
        this.showHideClass = 'fa fa-eye';
      }
    }
  };

  showPasswordMK() {
    if (this.target.password !== null) {
      if(this.inputTypeMK === 'password') {
        this.inputTypeMK = 'text';
        this.showHideClassMK = 'fa fa-eye-slash';
      } else {
        this.inputTypeMK = 'password';
        this.showHideClassMK = 'fa fa-eye';
      }
    }
  };
}
