import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-projects-style-four',
  templateUrl: './projects-style-four.component.html',
  styleUrls: ['./projects-style-four.component.scss']
})
export class ProjectsStyleFourComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
