import { ApiResponse,
    AppQthtApiService,
    AuthenticateRequest,
    AuthenticateResponseApiResponse,
    InsertUpdateModel
} from '../../../common/app-qtht.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HomeDemoSixService {

  constructor(
    private _api: AppQthtApiService
  ) { }

  AuthAuthenhome(body: AuthenticateRequest): Observable<AuthenticateResponseApiResponse> {
    return this._api.appAuthApiAuthAuthenhome(body);
  }

  Insertuser(body: InsertUpdateModel): Observable<ApiResponse> {
    return this._api.appQthtApiDangkychiaseInsertuser(body);
  }
}
