<!-- Start Navbar Area -->
<div class="navbar-area {{navbarClass}}">
    <div class="karlson-nav">
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light">
                <a class="navbar-brand" routerLink="/" style="display: contents;"><img src="{{path}}/assets/img/png/logophutho.png" alt="logo" class="logo">&nbsp;
                    <p class="logotext">Kho dữ liệu và <br/> Cổng chuyển đổi số tỉnh Phú Thọ</p>
                </a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="javascript:void(0)" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Lĩnh vực <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a href="/gis/" routerLinkActive="active" class="nav-link">Bản đồ</a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a href="/gtvt/" routerLinkActive="active" class="nav-link">Lĩnh vực Giao thông, Vận tải</a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a href="/noivu/" routerLinkActive="active" class="nav-link">Lĩnh vực Nội vụ</a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a href="/khdt/" routerLinkActive="active" class="nav-link">Lĩnh vực Kế hoạch và Đầu tư</a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a href="/taichinh/" routerLinkActive="active" class="nav-link">Lĩnh vực Tài chính</a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a href="/tnmt/" routerLinkActive="active" class="nav-link">Lĩnh vực Tài nguyên, Môi trường</a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a href="/ldvl/" routerLinkActive="active" class="nav-link">Lĩnh vực Lao động - Việc làm</a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a href="/kcndl/" routerLinkActive="active" class="nav-link">Lĩnh vực Quy hoạch cụm, khu công nghiệp,<br/> hạ tầng điện lưới</a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a href="/gddt/" routerLinkActive="active" class="nav-link">Lĩnh vực Giáo dục và Đào tạo</a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a href="/yte/" routerLinkActive="active" class="nav-link">Lĩnh vực Y tế</a>
                                </li>

                            </ul>
                        </li>
                        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                            <a href="https://dichvucong.phutho.gov.vn/" routerLinkActive="active" class="nav-link">Dịch vụ công trực tuyến</a>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="javascript:void(0)" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Tiện ích <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a routerLink="/hdaddacc" routerLinkActive="active" class="nav-link">Hướng dẫn tạo tài khoản</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/hdsearch" routerLinkActive="active" class="nav-link">Hướng dẫn tìm kiếm dữ liệu</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/" routerLinkActive="active" class="nav-link">Hướng dẫn tạo dịch vụ</a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a *ngIf="!user"  routerLink="/login" routerLinkActive="active" class="nav-link styleTimes">Đăng nhập</a>
                            <a *ngIf="user" class="nav-link dropdown-toggle styleTimes" href="javascript:void(0)" role="button" data-bs-toggle="dropdown" aria-expanded="false" style="width: auto;">
                                Xin chào: {{user.FullName}}
                                <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul *ngIf="user" class="dropdown-menu">
                                <!-- <li class="nav-item styleTimes">
                                    <span (click)="userInfo()" style="cursor: pointer;">Thông tin cá nhân</span>
                                </li>
                                <li class="nav-item styleTimes">
                                    <span (click)="doiMatKhau()" style="cursor: pointer;">Đổi mật khẩu</span>
                                </li>
                                <li class="nav-item styleTimes">
                                    <span (click)="dataAcessHistory()" style="cursor: pointer;">Lịch sử truy cập hệ thống</span>
                                </li> -->
                                <li class="nav-item styleTimes">
                                    <span (click)="dangXuat()" style="cursor: pointer;">Đăng xuất</span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <div class="others-options">
                        <div class="burger-menu">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->
