import { environment } from "src/environments/environment";

export const SUCCESS_RESPONSE = 'Success';
export const DUPLICATE_RESPONE = 'Duplicate';
export const NOTFOUND_RESPONE = 'NotFound';
export const SUCCESS_NOTICE = 'Thành công';
export const TITLE_NOTICE = 'Thông báo';
export const PAGE_SIZE = 10;
export const listSex = [
    {
        value: 1,
        text: 'Nam'
    },
    {
        value: 2,
        text: 'Nữ'
    },
    {
        value: 3,
        text: 'Khác'
    }
];
export const LBL_DISPLAY = {
  syncNgsp: 'Đồng bộ NGSP',
  add: 'Thêm mới',
  edit: 'Sửa',
  update: 'Cập nhập',
  delete: 'Xóa',
  view: 'Xem',
  search: 'Tìm kiếm',
  save: 'Lưu',
  print: 'In',
  close: 'Đóng',
  back: 'Quay lại',
  refresh: 'Làm mới',
  file: 'Chọn file',
  confirm: 'Xác nhận',
  cancel: 'Hủy',
  importFile: 'Import',
  downloadTemplate: 'Tải template',
  sync: 'Đồng bộ',
};

export const LST_APP = {
    APP_GDDT: 201,
    APP_GTVT: 84,
    APP_KHDT: 181,
    APP_LDVL: 424,
    APP_TNMT: 284,
    APP_TAICHINH: 41,
    APP_NOIVU: 61,
    APP_KCNDL: 384,
    APP_GIS: 404,
    APP_YTE: 221,
};

export const LST_APP_LINK_SSO = [
{
        link: environment.APP_PROVIDER + '/oauth2/authorize?response_type=code&client_id=' + environment.APP_GDDT_CLIENT_ID + '&redirect_uri=' + environment.APP_GDDT_REDIRECT + '&scope=openid',
appId: 201
},
{
        link: environment.APP_PROVIDER + '/oauth2/authorize?response_type=code&client_id=' + environment.APP_GTVT_CLIENT_ID + '&redirect_uri=' + environment.APP_GDDT_REDIRECT + '&scope=openid',
appId: 84
},
{
        link: environment.APP_PROVIDER + '/oauth2/authorize?response_type=code&client_id=' + environment.APP_KEHOACHDAUTU_CLIENT_ID + '&redirect_uri=' + environment.APP_GDDT_REDIRECT + '&scope=openid',
appId: 181
},
{
        link: environment.APP_PROVIDER + '/oauth2/authorize?response_type=code&client_id=' + environment.APP_LDTBXH_CLIENT_ID + '&redirect_uri=' + environment.APP_GDDT_REDIRECT + '&scope=openid',
appId: 424
},
{
        link: environment.APP_PROVIDER + '/oauth2/authorize?response_type=code&client_id=' + environment.APP_TNMT_CLIENT_ID + '&redirect_uri=' + environment.APP_GDDT_REDIRECT + '&scope=openid',
appId: 284
},
{
        link: environment.APP_PROVIDER + '/oauth2/authorize?response_type=code&client_id=' + environment.APP_TAICHINH_CLIENT_ID + '&redirect_uri=' + environment.APP_GDDT_REDIRECT + '&scope=openid',
appId: 41
},
{
        link: environment.APP_PROVIDER + '/oauth2/authorize?response_type=code&client_id=' + environment.APP_SONOIVU_CLIENT_ID + '&redirect_uri=' + environment.APP_GDDT_REDIRECT + '&scope=openid',
appId: 61
},
{
        link: environment.APP_PROVIDER + '/oauth2/authorize?response_type=code&client_id=' + environment.APP_KCNDL_CLIENT_ID + '&redirect_uri=' + environment.APP_GDDT_REDIRECT + '&scope=openid',
appId: 384
},
{
        link: environment.APP_GIS_SSO + '/oauth2/authorize?response_type=code&client_id=' + environment.APP_GIS_CLIENT_ID + '&redirect_uri=' + environment.APP_GIS_REDIRECT + '&scope=openid',
appId: 404
},
{
        link: environment.APP_PROVIDER + '/oauth2/authorize?response_type=code&client_id=' + environment.APP_SOYTE_CLIENT_ID + '&redirect_uri=' + environment.APP_GDDT_REDIRECT + '&scope=openid',
appId: 221
},
];
