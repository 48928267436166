<app-navbar></app-navbar>

<!-- Start Services Area -->
<section class="services-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Hướng dẫn tìm kiếm trên Website</h2>
        </div>

        <div class="row">
            <div class="col-lg-2 col-md-6 col-sm-6"></div>
            <div class="col-lg-10 col-md-6 col-sm-6">
                <p><b>Bước 1:</b> Người dùng truy cập Kho dữ liệu và Cổng chuyển đổi số tỉnh Phú Thọ</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2 col-md-6 col-sm-6"></div>
            <div class="col-lg-10 col-md-6 col-sm-6">
                <p><b>Bước 2:</b> Truy cập vào lĩnh vực cần tìm kiếm</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2 col-md-6 col-sm-6"></div>
            <div class="col-lg-10 col-md-6 col-sm-6">
                <p><b>Bước 3:</b> Tại ô tìm kiếm, nhập từ khóa cần tìm kiếm</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2 col-md-6 col-sm-6"></div>
            <div class="col-lg-9 col-md-6 col-sm-6">
                <img src="{{path}}/assets/img/services/hdsearch.png" alt="image">
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2 col-md-6 col-sm-6"></div>
            <div class="col-lg-10 col-md-6 col-sm-6">
                <p><b>Bước 4:</b> Lọc dữ liệu tìm kiếm</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2 col-md-6 col-sm-6"></div>
            <div class="col-lg-10 col-md-6 col-sm-6">
                <p>- Sắp xếp dữ liệu: Người dùng chọn loại sắp xếp mong muốn</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2 col-md-6 col-sm-6"></div>
            <div class="col-lg-9 col-md-6 col-sm-6">
                <img src="{{path}}/assets/img/services/hdsearch2.png" alt="image">
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->
