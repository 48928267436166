<app-navbar-style-three></app-navbar-style-three>

<!-- Start Big Data Banner Area -->
<div class="bd-banner-section">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="bd-banner-content">
                    <span class="sub-title">PROFESSIONAL COMPANY WITH RELIABLE SERVICES</span>
                    <h1>Customer Ai & <span>Big Data</span> Solution For Analytics</h1>
                    <p>We work hand-in-hand with industry-leading brands to help redefine the possibilities and potential of digital engagements We work hand-in-hand.</p>

                    <ul class="banner-btn">
                        <li>
                            <a href="contact.html" class="default-btn">
                                Get Started <span></span>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube">
                                <i class="flaticon-play-button"></i> 
                                Play Video
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="bd-banner-image">
                    <img src="assets/img/big-data-home/banner/main.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="image">

                    <div class="circle-shape"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="bd-banner-shape-1">
        <img src="assets/img/big-data-home/banner/shape-1.png" alt="image">
    </div>
    <div class="bd-banner-shape-2">
        <img src="assets/img/big-data-home/banner/shape-2.png" alt="image">
    </div>
    <div class="bd-banner-shape-3">
        <img src="assets/img/big-data-home/banner/shape-3.png" alt="image">
    </div>
</div>
<!-- End Big Data Banner Area -->

<!-- Start BD Fun Facts Area -->
<section class="bd-fun-facts-area">
    <div class="container-fluid">
        <div class="bd-fun-facts-inner-box pt-100 pb-70">
            <div class="row justify-content-center">
                <div class="col-lg-3 col-sm-6">
                    <div class="bd-single-fun-facts">
                        <div class="image-icon">
                            <img src="assets/img/big-data-home/fun-facts/image-1.png" alt="icon">
                        </div>
                        <h3><span class="odometer" data-count="2250">00</span><span class="sign-icon">+</span></h3>
                        <p>Cybersecurity Projects</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="bd-single-fun-facts">
                        <div class="image-icon">
                            <img src="assets/img/big-data-home/fun-facts/image-2.png" alt="icon">
                        </div>
                        <h3><span class="odometer" data-count="1400">00</span><span class="sign-icon">+</span></h3>
                        <p>Satisfied Clients</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="bd-single-fun-facts">
                        <div class="image-icon">
                            <img src="assets/img/big-data-home/fun-facts/image-3.png" alt="icon">
                        </div>
                        <h3><span class="odometer" data-count="850">00</span><span class="sign-icon">+</span></h3>
                        <p>Industries Served</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="bd-single-fun-facts">
                        <div class="image-icon">
                            <img src="assets/img/big-data-home/fun-facts/image-4.png" alt="icon">
                        </div>
                        <h3><span class="odometer" data-count="850">00</span><span class="sign-icon">K</span></h3>
                        <p>Positive Reviews</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End BD Fun Facts Area -->

<!-- Start BD Featured Area -->
<div class="bd-featured-area pt-100 pb-70">
    <div class="container">
        <div class="section-title with-line-text-with-white-color">
            <span class="sub-title">OUR FEATURED</span>
            <h2>The Power To Complete <span>Website</span> And Big Data Solutions</h2>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-3 col-sm-6">
                <div class="bd-featured-card text-center">
                    <div class="featured-image">
                        <img src="assets/img/big-data-home/featured/image-1.png" alt="image">
                    </div>
                    <h3>Supply Chain</h3>
                    <p>Lorem ipsum dolor sit amet, is consectetur adipiscing elit, sed do eiusmo.</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="bd-featured-card text-center">
                    <div class="featured-image">
                        <img src="assets/img/big-data-home/featured/image-2.png" alt="image">
                    </div>
                    <h3>Predictive Maintenance</h3>
                    <p>Lorem ipsum dolor sit amet, is consectetur adipiscing elit, sed do eiusmo.</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="bd-featured-card text-center">
                    <div class="featured-image">
                        <img src="assets/img/big-data-home/featured/image-3.png" alt="image">
                    </div>
                    <h3>IT Optimization</h3>
                    <p>Lorem ipsum dolor sit amet, is consectetur adipiscing elit, sed do eiusmo.</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="bd-featured-card text-center">
                    <div class="featured-image">
                        <img src="assets/img/big-data-home/featured/image-4.png" alt="image">
                    </div>
                    <h3>Critical Infrastructure</h3>
                    <p>Lorem ipsum dolor sit amet, is consectetur adipiscing elit, sed do eiusmo.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="bd-featured-shape">
        <img src="assets/img/big-data-home/featured/shape.png" alt="image">
    </div>
</div>
<!-- End BD Featured Area -->

<!-- Start BD About Area -->
<div class="bd-about-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="bd-about-image">
                    <img src="assets/img/big-data-home/about/about.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="bd-about-content">
                    <span class="sub-title">ABOUT KARLSON</span>
                    <h3>Professional Services <span>Provider</span> With Big Data  Solution</h3>
                    <p>We work hand-in-hand with industry-leading brands to help redefine the possibilities and potential of digital engagements We work hand-in-hand with industry-leading brands to help redefine the possibilities and potential.</p>

                    <div class="inner-content-card">
                        <div class="icon-image">
                            <img src="assets/img/big-data-home/about/image-1.png" alt="image">
                        </div>
                        <h4>Big Data Management</h4>
                        <p>We work hand-in-hand with industry-leading brands to help redefine the possibilities.</p>
                    </div>
                    <div class="inner-content-card">
                        <div class="icon-image">
                            <img src="assets/img/big-data-home/about/image-2.png" alt="image">
                        </div>
                        <h4>Big Data Analysis</h4>
                        <p>We work hand-in-hand with industry-leading brands to help redefine the possibilities.</p>
                    </div>
                    <div class="inner-content-card">
                        <div class="icon-image">
                            <img src="assets/img/big-data-home/about/image-3.png" alt="image">
                        </div>
                        <h4>Big Data Integration</h4>
                        <p>We work hand-in-hand with industry-leading brands to help redefine the possibilities.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="bd-about-shape">
        <img src="assets/img/big-data-home/about/shape.png" alt="image">
    </div>
</div>
<!-- End BD About Area -->

<!-- Start CS Partner Area -->
<div class="cs-partner-area pb-70">
    <div class="container-fluid">
        <div class="section-title with-line-text-with-white-color">
            <span class="sub-title">THE POWER OF KARLSON</span>
            <h2>Karlson Has Been Trusted <span>Over 2300 +</span> Companies In The World</h2>
        </div>

        <div class="cs-partner-slides owl-carousel owl-theme">
            <div class="cs-partner-item">
                <a href="#">
                    <img src="assets/img/cyber-security-home/partner/partner-1.png" alt="image">
                </a>
            </div>
            <div class="cs-partner-item">
                <a href="#">
                    <img src="assets/img/cyber-security-home/partner/partner-2.png" alt="image">
                </a>
            </div>
            <div class="cs-partner-item">
                <a href="#">
                    <img src="assets/img/cyber-security-home/partner/partner-3.png" alt="image">
                </a>
            </div>
            <div class="cs-partner-item">
                <a href="#">
                    <img src="assets/img/cyber-security-home/partner/partner-4.png" alt="image">
                </a>
            </div>
            <div class="cs-partner-item">
                <a href="#">
                    <img src="assets/img/cyber-security-home/partner/partner-5.png" alt="image">
                </a>
            </div>
            <div class="cs-partner-item">
                <a href="#">
                    <img src="assets/img/cyber-security-home/partner/partner-6.png" alt="image">
                </a>
            </div>
            <div class="cs-partner-item">
                <a href="#">
                    <img src="assets/img/cyber-security-home/partner/partner-7.png" alt="image">
                </a>
            </div>
            <div class="cs-partner-item">
                <a href="#">
                    <img src="assets/img/cyber-security-home/partner/partner-8.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</div>
<!-- End CS Partner Area -->

<!-- Start BD Services Area -->
<div class="bd-services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title with-line-text-with-white-color">
            <span class="sub-title">OUR SERVICES</span>
            <h2>See Wide Range Of Our <span>Services</span> With Big Data Solutions</h2>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6">
                <div class="bd-services-card">
                    <div class="services-image">
                        <img src="assets/img/big-data-home/services/image-1.png" alt="image">
                    </div>
                    <h3>
                        <a href="single-services.html">Big Data Integration</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet, is consectetur adipiscing elit, sed do eiusmo. Lorem ipsum dolor sit amet, is consectetur adipiscing elit.</p>
                    <a href="single-services.html" class="get-started-btn">GET STARTED</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="bd-services-card">
                    <div class="services-image">
                        <img src="assets/img/big-data-home/services/image-2.png" alt="image">
                    </div>
                    <h3>
                        <a href="single-services.html">Big Data Management</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet, is consectetur adipiscing elit, sed do eiusmo. Lorem ipsum dolor sit amet, is consectetur adipiscing elit.</p>
                    <a href="single-services.html" class="get-started-btn">GET STARTED</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="bd-services-card">
                    <div class="services-image">
                        <img src="assets/img/big-data-home/services/image-3.png" alt="image">
                    </div>
                    <h3>
                        <a href="single-services.html">Big Data Analytics</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet, is consectetur adipiscing elit, sed do eiusmo. Lorem ipsum dolor sit amet, is consectetur adipiscing elit.</p>
                    <a href="single-services.html" class="get-started-btn">GET STARTED</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="bd-services-card">
                    <div class="services-image">
                        <img src="assets/img/big-data-home/services/image-4.png" alt="image">
                    </div>
                    <h3>
                        <a href="single-services.html">Content Management</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet, is consectetur adipiscing elit, sed do eiusmo. Lorem ipsum dolor sit amet, is consectetur adipiscing elit.</p>
                    <a href="single-services.html" class="get-started-btn">GET STARTED</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="bd-services-card">
                    <div class="services-image">
                        <img src="assets/img/big-data-home/services/image-5.png" alt="image">
                    </div>
                    <h3>
                        <a href="single-services.html">Cloud Management</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet, is consectetur adipiscing elit, sed do eiusmo. Lorem ipsum dolor sit amet, is consectetur adipiscing elit.</p>
                    <a href="single-services.html" class="get-started-btn">GET STARTED</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="bd-services-card">
                    <div class="services-image">
                        <img src="assets/img/big-data-home/services/image-6.png" alt="image">
                    </div>
                    <h3>
                        <a href="single-services.html">Data System Management</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet, is consectetur adipiscing elit, sed do eiusmo. Lorem ipsum dolor sit amet, is consectetur adipiscing elit.</p>
                    <a href="single-services.html" class="get-started-btn">GET STARTED</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End BD Services Area -->

<!-- Start BD Choose Area -->
<div class="bd-choose-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="bd-choose-content">
                    <span class="sub-title">WHY CHOOSE KARLSON</span>
                    <h3>Do What Matters <span>And We'll</span> Handle The Back Office Routine</h3>
                    <p>We work hand-in-hand with industry-leading brands to help redefine the possibilities and potential of digital engagements We work hand-in-hand with industry-leading brands to help redefine the possibilities and potential.</p>

                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-sm-6">
                            <ul class="list">
                                <li><i class="fa-solid fa-check"></i> Demand Prediction</li>
                                <li><i class="fa-solid fa-check"></i> Lead Generation</li>
                                <li><i class="fa-solid fa-check"></i> Product Line Optimization</li>
                            </ul>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <ul class="list">
                                <li><i class="fa-solid fa-check"></i> Preventive Maintenance</li>
                                <li><i class="fa-solid fa-check"></i> Logistics Optimization</li>
                                <li><i class="fa-solid fa-check"></i> Warehouse Optimization</li>
                            </ul>
                        </div>
                    </div>
                    <div class="choose-btn">
                        <a href="contact.html" class="default-btn">
                            Get Started <span></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="bd-choose-image">
                    <img src="assets/img/big-data-home/choose.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End BD Choose Area -->

<!-- Start BD Overview Area -->
<div class="bd-overview-area pt-100 pb-70 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="bd-overview-content">
            <div class="row justify-content-center align-items-center">
                <div class="col-lg-6 col-sm-6">
                    <div class="bd-overview-card">
                        <div class="overview-image">
                            <img src="assets/img/big-data-home/overview/image-1.png" alt="image">
                        </div>
                        <h3>Safe And Secure</h3>
                        <p>Lorem ipsum dolor sit amet, is consectetur adipiscing elit, sed do eiusmo.</p>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                    <div class="bd-overview-card">
                        <div class="overview-image">
                            <img src="assets/img/big-data-home/overview/image-2.png" alt="image">
                        </div>
                        <h3>Operations Manage</h3>
                        <p>Lorem ipsum dolor sit amet, is consectetur adipiscing elit, sed do eiusmo.</p>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                    <div class="bd-overview-card">
                        <div class="overview-image">
                            <img src="assets/img/big-data-home/overview/image-3.png" alt="image">
                        </div>
                        <h3>Experts Support</h3>
                        <p>Lorem ipsum dolor sit amet, is consectetur adipiscing elit, sed do eiusmo.</p>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                    <div class="bd-overview-card">
                        <div class="overview-image">
                            <img src="assets/img/big-data-home/overview/image-4.png" alt="image">
                        </div>
                        <h3>Risk Management</h3>
                        <p>Lorem ipsum dolor sit amet, is consectetur adipiscing elit, sed do eiusmo.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End BD Overview Area -->

<!-- Start Unique Feedback Area -->
<div class="unique-feedback-area with-linear-gradient-color pt-100">
    <div class="container">
        <div class="section-title with-line-text-with-white-color">
            <span class="sub-title">CREATING FEEDBACK</span>
            <h2>Check What’s Our Clients <span>Feedback</span> On Our Big Data Services</h2>
        </div>

        <div class="unique-feedback-slides owl-carousel owl-theme">
            <div class="unique-single-feedback">
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>
                <div class="client-info">
                    <img src="assets/img/cryptocurrency-home/user1.jpg" alt="image">

                    <h3>Jason Roy</h3>
                    <span>Manager</span>
                </div>
                <div class="quote">
                    <img src="assets/img/cryptocurrency-home/quote.png" alt="image">
                </div>
            </div>

            <div class="unique-single-feedback">
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>
                <div class="client-info">
                    <img src="assets/img/cryptocurrency-home/user2.jpg" alt="image">

                    <h3>James Anderson</h3>
                    <span>Web Developer</span>
                </div>
                <div class="quote">
                    <img src="assets/img/cryptocurrency-home/quote.png" alt="image">
                </div>
            </div>

            <div class="unique-single-feedback">
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>
                <div class="client-info">
                    <img src="assets/img/cryptocurrency-home/user3.jpg" alt="image">

                    <h3>Jason Roy</h3>
                    <span>Manager</span>
                </div>
                <div class="quote">
                    <img src="assets/img/cryptocurrency-home/quote.png" alt="image">
                </div>
            </div>

            <div class="unique-single-feedback">
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>
                <div class="client-info">
                    <img src="assets/img/cryptocurrency-home/user1.jpg" alt="image">

                    <h3>James Anderson</h3>
                    <span>Web Developer</span>
                </div>
                <div class="quote">
                    <img src="assets/img/cryptocurrency-home/quote.png" alt="image">
                </div>
            </div>

            <div class="unique-single-feedback">
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>
                <div class="client-info">
                    <img src="assets/img/cryptocurrency-home/user2.jpg" alt="image">

                    <h3>Jason Roy</h3>
                    <span>Manager</span>
                </div>
                <div class="quote">
                    <img src="assets/img/cryptocurrency-home/quote.png" alt="image">
                </div>
            </div>

            <div class="unique-single-feedback">
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>
                <div class="client-info">
                    <img src="assets/img/cryptocurrency-home/user3.jpg" alt="image">

                    <h3>James Anderson</h3>
                    <span>Web Developer</span>
                </div>
                <div class="quote">
                    <img src="assets/img/cryptocurrency-home/quote.png" alt="image">
                </div>
            </div>
        </div>
    </div>


</div>
<!-- End Unique Feedback Area -->

<!-- Start Blog Area -->
<div class="blog-wrap-area pt-100 pb-70">
    <div class="container">
        <div class="section-title with-line-text-with-white-color">
            <span class="sub-title">LATEST NEWS</span>
            <h2>Explore, Learn And Stay <span>Up To Date</span> With The Latest News</h2>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-card">
                    <div class="tag">
                        <span>LATEST NEWS</span>
                    </div>
                    <ul class="post-meta">
                        <li><i class='bx bxs-calendar'></i> 17th January</li>
                        <li><i class='bx bx-message-dots'></i> 0 comment</li>
                    </ul>
                    <h3>
                        <a href="single-blog.html">The Launch Of A Revamped User Referral Program</a>
                    </h3>
                    <p>We work hand-in-hand with industry-leading brands to help redefine the possibilities and potential of digital engagements We work.</p>
                    <div class="info">
                        <img src="assets/img/cryptocurrency-home/user1.jpg" alt="image">

                        <h3>Thomas Adison</h3>
                        <span>Manager</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-card">
                    <div class="tag">
                        <span>LATEST NEWS</span>
                    </div>
                    <ul class="post-meta">
                        <li><i class='bx bxs-calendar'></i> 17th January</li>
                        <li><i class='bx bx-message-dots'></i> 0 comment</li>
                    </ul>
                    <h3>
                        <a href="single-blog.html">Making Peace With The Feast Or Famine Of Freelancing</a>
                    </h3>
                    <p>We work hand-in-hand with industry-leading brands to help redefine the possibilities and potential of digital engagements We work.</p>
                    <div class="info">
                        <img src="assets/img/cryptocurrency-home/user2.jpg" alt="image">

                        <h3>James Anderson</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-card">
                    <div class="tag">
                        <span>LATEST NEWS</span>
                    </div>
                    <ul class="post-meta">
                        <li><i class='bx bxs-calendar'></i> 17th January</li>
                        <li><i class='bx bx-message-dots'></i> 0 comment</li>
                    </ul>
                    <h3>
                        <a href="single-blog.html">Here Are The 5 Most Telling Signs Of Micromanagement</a>
                    </h3>
                    <p>We work hand-in-hand with industry-leading brands to help redefine the possibilities and potential of digital engagements We work.</p>
                    <div class="info">
                        <img src="assets/img/cryptocurrency-home/user3.jpg" alt="image">

                        <h3>Sarah Taylor</h3>
                        <span>Designer</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Blog Area -->

<!-- Start Subscribe Wrap Area -->
<div class="subscribe-wrap-area">
    <div class="container">
        <div class="subscribe-wrap-inner-box">
            <div class="subscribe-content">
                <h2>Join Our Newsletter</h2>

                <form class="newsletter-form" data-toggle="validator">
                    <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL" required autocomplete="off">

                    <button type="submit">Subscribe</button>
                    <div id="validator-newsletter" class="form-result"></div>
                </form>
            </div>

            <div class="subscribe-shape-1">
                <img src="assets/img/cryptocurrency-home/subscribe/shape-1.png" alt="image">
            </div>
            <div class="subscribe-shape-2">
                <img src="assets/img/cryptocurrency-home/subscribe/shape-2.png" alt="image">
            </div>
            <div class="subscribe-shape-3">
                <img src="assets/img/cryptocurrency-home/subscribe/shape-3.png" alt="image">
            </div>
        </div>
    </div>
</div>
<!-- End Subscribe Wrap Area -->