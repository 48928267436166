<app-navbar></app-navbar>

<!-- Start Main Banner Area -->
<div class="portfolio-agency-banner pa-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container mt-80">
                <div class="banner-content text-center">
                    <h1>Make Your Awesome Projects Portfolio with Karlson</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    
                    <div class="banner-btn">
                        <a routerLink="/contact" class="default-btn me-4">
                            Get Started <span></span>
                        </a>

                        <a routerLink="/login" class="default-btn-two">
                            Try It Free <span></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <!-- Shape Images -->
    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Main Banner Area -->

<!-- Project area -->
<div class="works-area ptb-100">
    <div class="container-fluid">
        <div class="section-title">
            <h2>All Projects</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-xl-3">
                <div class="work-card">
                    <img src="assets/img/works/work1.jpg" alt="image">
                    
                    <div class="content">
                        <span>
                            <a routerLink="/projects">Development</a>
                        </span>
                        <h3>
                            <a routerLink="/project-details">Designing a better cinema experience</a>
                        </h3>
                            
                        <a class="work-btn" routerLink="/project-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-xl-3">
                <div class="work-card">
                    <img src="assets/img/works/work2.jpg" alt="image">
                    
                    <div class="content">
                        <span>
                            <a routerLink="/projects">Web Design</a>
                        </span>
                        <h3>
                            <a routerLink="/project-details">Building awesome design process within teams</a>
                        </h3>
                            
                        <a class="work-btn" routerLink="/project-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-xl-3">
                <div class="work-card">
                    <img src="assets/img/works/work3.jpg" alt="image">
                    
                    <div class="content">
                        <span>
                            <a routerLink="/projects">eCommerce</a>
                        </span>
                        <h3>
                            <a routerLink="/project-details">How intercom brings play eCommerce</a>
                        </h3>
                            
                        <a class="work-btn" routerLink="/project-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-xl-3">
                <div class="work-card">
                    <img src="assets/img/works/work4.jpg" alt="image">
                    
                    <div class="content">
                        <span>
                            <a routerLink="/projects">React</a>
                        </span>
                        <h3>
                            <a routerLink="/project-details">Developing a super-fast project with React Js</a>
                        </h3>
                            
                        <a class="work-btn" routerLink="/project-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-xl-3">
                <div class="work-card">
                    <img src="assets/img/works/work5.jpg" alt="image">
                    
                    <div class="content">
                        <span>
                            <a routerLink="/projects">Angular</a>
                        </span>
                        <h3>
                            <a routerLink="/project-details">Developing a super-fast project with Angular Js</a>
                        </h3>
                            
                        <a class="work-btn" routerLink="/project-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-xl-3">
                <div class="work-card">
                    <img src="assets/img/works/work6.jpg" alt="image">
                    
                    <div class="content">
                        <span>
                            <a routerLink="/projects">Development</a>
                        </span>
                        <h3>
                            <a routerLink="/project-details">Developing a super-fast App with React Js</a>
                        </h3>
                            
                        <a class="work-btn" routerLink="/project-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-xl-3">
                <div class="work-card">
                    <img src="assets/img/works/work7.jpg" alt="image">
                    
                    <div class="content">
                        <span>
                            <a routerLink="/projects">Graphic Design</a>
                        </span>
                        <h3>
                            <a routerLink="/project-details">Graphic Design Design the Web, Mobile, and eCommerce</a>
                        </h3>
                            
                        <a class="work-btn" routerLink="/project-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-xl-3">
                <div class="work-card">
                    <img src="assets/img/works/work8.jpg" alt="image">
                    
                    <div class="content">
                        <span>
                            <a routerLink="/projects">Bootstrap</a>
                        </span>
                        <h3>
                            <a routerLink="/project-details">Bootstrap Redesigning the New York times app</a>
                        </h3>
                            
                        <a class="work-btn" routerLink="/project-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-xl-3">
                <div class="work-card">
                    <img src="assets/img/works/work9.jpg" alt="image">
                    
                    <div class="content">
                        <span>
                            <a routerLink="/projects">App Development</a>
                        </span>
                        <h3>
                            <a routerLink="/project-details">We provide any type of app development</a>
                        </h3>
                            
                        <a class="work-btn" routerLink="/project-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-xl-3">
                <div class="work-card">
                    <img src="assets/img/works/work10.jpg" alt="image">
                    
                    <div class="content">
                        <span>
                            <a routerLink="/projects">Marketing</a>
                        </span>
                        <h3>
                            <a routerLink="/project-details">We provide any type of marketing support</a>
                        </h3>
                            
                        <a class="work-btn" routerLink="/project-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-xl-3">
                <div class="work-card">
                    <img src="assets/img/works/work11.jpg" alt="image">
                    
                    <div class="content">
                        <span>
                            <a routerLink="/projects">Email Marketing</a>
                        </span>
                        <h3>
                            <a routerLink="/project-details">We provide any type of Email Marketing</a>
                        </h3>
                            
                        <a class="work-btn" routerLink="/project-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-xl-3">
                <div class="work-card">
                    <img src="assets/img/works/work12.jpg" alt="image">
                    
                    <div class="content">
                        <span>
                            <a routerLink="/projects">Email Marketing</a>
                        </span>
                        <h3>
                            <a routerLink="/project-details">We provide any type of Marketing & Reporting</a>
                        </h3>
                            
                        <a class="work-btn" routerLink="/project-details">Read More</a>
                    </div>
                </div>
            </div>

            <!-- Pagination -->
            <div class="col-lg-12 col-sm-12">
                <div class="pagination-area">
                    <a routerLink="/index-9" class="prev page-numbers">
                        <i class="fas fa-angle-double-left"></i>
                    </a>
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/index-9" class="page-numbers">2</a>
                    <a routerLink="/index-9" class="page-numbers">3</a>
                    <a routerLink="/index-9" class="page-numbers">4</a>
                    <a routerLink="/index-9" class="next page-numbers">
                        <i class="fas fa-angle-double-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End project area -->

<!-- Start Fun Facts Area -->
<section class="fun-facts-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="324">00</span><span class="sign-icon">+</span></h3>
                    <p>Project Completed</p>
                    <div class="back-text">P</div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="100">00</span><span class="sign-icon">%</span></h3>
                    <p>Client Satisfaction</p>
                    <div class="back-text">C</div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="13">00</span></h3>
                    <p>Awards</p>
                    <div class="back-text">A</div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="48">00</span><span class="sign-icon">+</span></h3>
                    <p>Expert Members</p>
                    <div class="back-text">E</div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Fun Facts Area -->

<!-- Start Feedback Area -->
<section class="feedback-area feedback-area-two ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Testimonials</span>
            <h2>Some Lovely Feedback From Our Clients</h2>
        </div>
    </div>

    <div class="feedback-slides owl-carousel owl-theme">
        <div class="single-feedback-item border">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>

            <div class="client-info">
                <img src="assets/img/client-image/client1.jpg" alt="image">

                <h3>Jason Roy</h3>
                <span>Manager</span>
            </div>
        </div>

        <div class="single-feedback-item border">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>

            <div class="client-info">
                <img src="assets/img/client-image/client2.jpg" alt="image">

                <h3>James Anderson</h3>
                <span>Web Developer</span>
            </div>
        </div>

        <div class="single-feedback-item border">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>

            <div class="client-info">
                <img src="assets/img/client-image/client3.jpg" alt="image">

                <h3>Sarah Taylor</h3>
                <span>Designer</span>
            </div>
        </div>

        <div class="single-feedback-item border">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>

            <div class="client-info">
                <img src="assets/img/client-image/client4.jpg" alt="image">

                <h3>Steven Smith</h3>
                <span>Manager</span>
            </div>
        </div>

        <div class="single-feedback-item border">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>

            <div class="client-info">
                <img src="assets/img/client-image/client5.jpg" alt="image">

                <h3>Tom Nessham</h3>
                <span>EnvyTheme</span>
            </div>
        </div>
    </div>
</section>
<!-- End Feedback Area -->

<!-- Pricing Area -->
<section class="pricing-area-two pt-100 pb-70 bg-f4f7fe">
    <div class="container">
        <div class="section-title">
            <h2>Our Affordable Pricing Plans</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing-table center">
                    <div class="pricing-header">
                        <h3>Weekly plan</h3>
                    </div>

                    <div class="price">
                        <sup>$</sup> 120 <sub>/ Per week</sub>
                    </div>

                    <ul class="pricing-features">
                        <li>
                            <i class='bx bxs-badge-check'></i>
                            SEO & Branding
                        </li>
                        <li>
                            <i class='bx bxs-badge-check'></i>
                            Digital Marketing
                        </li>
                        <li>
                            <i class='bx bxs-badge-check'></i>
                            Google Analytics
                        </li>
                        <li>
                            <i class='bx bxs-badge-check'></i>
                            Branding Solutions
                        </li>
                        <li>
                            <i class='bx bxs-badge-check'></i>
                            Digital Accounts
                        </li>
                        <li>
                            <i class='bx bxs-x-circle red'></i>
                            Pay-per-Click
                        </li>
                        <li> 
                            <i class='bx bxs-x-circle red'></i>
                            24/7 Support
                        </li>
                    </ul>

                    <div class="btn-box">
                        <a class="default-btn" routerLink="/">
                            Select the plan 
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing-table center">
                    <div class="pricing-header">
                        <h3>Month plan</h3>
                    </div>

                    <div class="price">
                        <sup>$</sup> 840 <sub>/ Per month</sub>
                    </div>

                    <ul class="pricing-features">
                        <li>
                            <i class='bx bxs-badge-check'></i>
                            SEO & Branding
                        </li>
                        <li>
                            <i class='bx bxs-badge-check'></i>
                            Digital Marketing
                        </li>
                        <li>
                            <i class='bx bxs-badge-check'></i>
                            Google Analytics
                        </li>
                        <li>
                            <i class='bx bxs-badge-check'></i>
                            Branding Solutions
                        </li>
                        <li>
                            <i class='bx bxs-badge-check'></i>
                            Digital Accounts
                        </li>
                        <li>
                            <i class='bx bxs-badge-check'></i>
                            Pay-per-Click
                        </li>
                        <li> 
                            <i class='bx bxs-x-circle red'></i>
                            24/7 Support
                        </li>
                    </ul>

                    <div class="btn-box">
                        <a class="default-btn" routerLink="/">
                            Select the plan 
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing-table center">
                    <div class="pricing-header">
                        <h3>Yearly plan</h3>
                    </div>

                    <div class="price">
                        <sup>$</sup> 3,600 <sub> / Per yearly</sub>
                    </div>

                    <ul class="pricing-features">
                        <li>
                            <i class='bx bxs-badge-check'></i>
                            SEO & Branding
                        </li>
                        <li>
                            <i class='bx bxs-badge-check'></i>
                            Digital Marketing
                        </li>
                        <li>
                            <i class='bx bxs-badge-check'></i>
                            Google Analytics
                        </li>
                        <li>
                            <i class='bx bxs-badge-check'></i>
                            Branding Solutions
                        </li>
                        <li>
                            <i class='bx bxs-badge-check'></i>
                            Digital Accounts
                        </li>
                        <li>
                            <i class='bx bxs-badge-check'></i>
                            Pay-per-Click
                        </li>
                        <li> 
                            <i class='bx bxs-badge-check'></i>
                            24/7 Support
                        </li>
                    </ul>

                    <div class="btn-box">
                        <a class="default-btn" routerLink="/">
                            Select the plan 
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Shape Images -->
    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</section>
<!-- End Pricing Area -->

<!-- Start Team Area -->
<section class="team-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Team</span>
            <h2>Our Expert Team</h2>
        </div>

        <div class="team-slider owl-carousel owl-theme">
            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/team10.jpg" alt="team">

                    <div class="social">
                        <a href="#" target="_blank">
                            <i class="fab fa-linkedin"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>

                <div class="content">
                    <h3>John Smith</h3>
                    <span>CEO & Founder</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/team11.jpg" alt="team">

                    <div class="social">
                        <a href="#" target="_blank">
                            <i class="fab fa-linkedin"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>

                <div class="content">
                    <h3>Lucy Eva</h3>
                    <span>Backend Team Leader</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/team12.jpg" alt="team">

                    <div class="social">
                        <a href="#" target="_blank">
                            <i class="fab fa-linkedin"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>

                <div class="content">
                    <h3>Steven Smith</h3>
                    <span>Frontend Team Leader</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/team13.jpg" alt="team">

                    <div class="social">
                        <a href="#" target="_blank">
                            <i class="fab fa-linkedin"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>

                <div class="content">
                    <h3>Steven Smith</h3>
                    <span>Web Developer</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/team14.jpg" alt="team">

                    <div class="social">
                        <a href="#" target="_blank">
                            <i class="fab fa-linkedin"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>

                <div class="content">
                    <h3>Williams Smith</h3>
                    <span>Web Developer</span>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</section>
<!-- End Team Area -->

<!-- Lets work Area -->
<section class="subscribe-area bg-F4F7FC">
    <div class="subscribe-inner-area lets-work jarallax" data-jarallax='{"speed": 0.3}'>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <span class="sub-title">READY TO DO THIS</span>
                    <h2>Let's get to work!</h2>
                </div>

                <div class="col-lg-6">
                    <div class="contact-btn">
                        <a routerLink="/contact" class="default-btn">
                            Contact Us <span></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Lets work Area -->

<!-- Start Partner Area -->
<section class="partner-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Clients</span>
            <h2>Our Loving Clients</h2>
            <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication.</p>
        </div>

        <div class="row align-items-center">
            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner1.png" alt="image">
                    <img src="assets/img/partner-image/partner1.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner2.png" alt="image">
                    <img src="assets/img/partner-image/partner2.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner3.png" alt="image">
                    <img src="assets/img/partner-image/partner3.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner4.png" alt="image">
                    <img src="assets/img/partner-image/partner4.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner5.png" alt="image">
                    <img src="assets/img/partner-image/partner5.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner6.png" alt="image">
                    <img src="assets/img/partner-image/partner6.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner7.png" alt="image">
                    <img src="assets/img/partner-image/partner7.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner8.png" alt="image">
                    <img src="assets/img/partner-image/partner8.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner9.png" alt="image">
                    <img src="assets/img/partner-image/partner9.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner10.png" alt="image">
                    <img src="assets/img/partner-image/partner10.png" alt="image">
                </a>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
</section>
<!-- End Partner Area -->

<!-- Start Subscribe Area -->
<section class="subscribe-area pb-100">
    <div class="container">
        <div class="subscribe-inner-area jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="subscribe-content">
                <span class="sub-title">Get Started Instantly!</span>
                <h2>Get only new update from this newsletter</h2>

                <form class="newsletter-form">
                    <input type="email" class="input-newsletter" placeholder="Enter your email" name="email" required autocomplete="off">
                    <button type="submit">Subscribe</button>
                </form>
            </div>
        </div>
    </div>
</section>
<!-- End Subscribe Area -->