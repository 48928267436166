import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-demo-twelve',
  templateUrl: './home-demo-twelve.component.html',
  styleUrls: ['./home-demo-twelve.component.scss']
})
export class HomeDemoTwelveComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
